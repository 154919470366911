import React, { HTMLProps, useRef } from 'react'
import { mergeProps, useButton } from 'react-aria'
import styled from 'styled-components'
import { Box, BoxType } from '../box'
import { AriaProps } from '../../components/types'
import { useFocusVisible } from '../../components/hooks'
import { splitAriaProps } from '../../components/helpers'

const StyledBox = styled(Box)<{ isFocusVisible: boolean }>`
  cursor: pointer;

  :disabled {
    cursor: auto;
  }

  :focus {
    outline: none;
    box-shadow: ${({ isFocusVisible, theme }) =>
      isFocusVisible && theme.shadows['border/focus-indicator']};
  }
`

type Props = BoxType &
  Omit<HTMLProps<HTMLButtonElement>, 'onClick'> & {
    ariaProps?: AriaProps
    onClick?: AriaProps['onPress']
  }

export const UnstyledButton = ({ onClick, disabled, ...props }: Props) => {
  const { elementRef, domProps, reactAriaProps } = splitAriaProps(props.ariaProps)
  const fallbackRef = useRef<HTMLButtonElement>(null)
  const { isFocusVisible, focusProps } = useFocusVisible()
  const ref = elementRef ?? fallbackRef
  const { buttonProps } = useButton(
    { onPress: onClick, isDisabled: disabled, ...reactAriaProps },
    ref
  )

  return (
    <StyledBox
      as='button'
      ref={ref}
      type='button'
      p={0}
      m={0}
      borderRadius='none'
      border='none'
      bg='transparent'
      textAlign='start'
      display='block'
      isFocusVisible={isFocusVisible}
      {...mergeProps(props, buttonProps, focusProps, domProps)}
    />
  )
}
